<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <div class="drawer-header d-flex align-center">
      <span class="font-weight-semibold text-base text--primary">{{ $t('addEmployee') }}</span>
      <v-spacer></v-spacer>
      <v-btn icon small @click="resetEmployeeData">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-subheader v-t="'basicData'" />
        <v-text-field
          v-model="employeeData.user.firstName"
          outlined
          dense
          :rules="[validators.required]"
          :label="$t('firstNameRequired')"
          :placeholder="$t('firstName', { postfix: '' })"
          hide-details="auto"
          maxlength="150"
          class="mb-3"
        ></v-text-field>

        <v-text-field
          v-model="employeeData.user.lastName"
          :rules="[validators.required]"
          outlined
          dense
          :label="$t('lastNameRequired')"
          :placeholder="$t('lastName', { postfix: '' })"
          hide-details="auto"
          maxlength="150"
          class="mb-3"
        ></v-text-field>

        <v-expansion-panels v-model="panel" class="mb-3" multiple>
          <v-expansion-panel v-for="(expansionPanel, index) in expansionPanels" :key="index">
            <v-expansion-panel-header> {{ $t(expansionPanel.title) }} </v-expansion-panel-header>
            <v-expansion-panel-content v-if="index === 0">
              <v-text-field
                v-model="employeeData.user.email"
                :rules="[validators.emailValidator]"
                outlined
                dense
                type="email"
                :label="$t('email', { postfix: '' })"
                :placeholder="$t('email', { postfix: '' })"
                hide-details="auto"
                maxlength="254"
                class="mb-3"
              ></v-text-field>

              <v-autocomplete
                v-model="employeeData.status"
                :label="$t('status', { postfix: '' })"
                :placeholder="$t('status', { postfix: '' })"
                :items="statusChoices"
                class="mb-3"
                outlined
                dense
                clearable
                hide-details
              ></v-autocomplete>

              <v-combobox
                v-model="employeeData.tags"
                :items="tagsChoices"
                :label="$t('tag')"
                :placeholder="$t('tag')"
                item-text="tag"
                item-value="id"
                class="mb-3"
                multiple
                outlined
                dense
                hide-details="auto"
                chips
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    small
                    label
                    color="info"
                    outlined
                    class="font-weight-semibold disable-min-height--chip mt-1"
                  >
                    <span class="pr-3">{{ item === Object(item) ? item.tag : item }}</span>
                    <v-icon x-small @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-combobox>

              <v-text-field
                v-model="employeeData.user.phone"
                outlined
                dense
                :label="$t('phoneNumber')"
                :placeholder="$t('phoneNumber')"
                hide-details="auto"
                maxlength="31"
                class="mb-3"
              ></v-text-field>

              <v-autocomplete
                v-model="employeeData.languages"
                :label="$t('language')"
                :placeholder="$t('language')"
                :items="languageChoices"
                outlined
                dense
                multiple
                hide-details="auto"
                :search-input.sync="searchInput"
                class="mb-3"
                @input="searchInput = null"
              ></v-autocomplete>

              <v-menu
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="employeeData.workingStartDay"
                    :label="$t('startWorkingDay')"
                    :placeholder="$t('startWorkingDay')"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    class="mb-3"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="employeeData.workingStartDay"
                  :first-day-of-week="1"
                  :locale="$i18n.locale"
                ></v-date-picker>
              </v-menu>

              <v-text-field
                v-model="employeeData.maritalStatus"
                outlined
                dense
                :label="$t('martialStatus')"
                :placeholder="$t('martialStatus')"
                hide-details="auto"
                maxlength="100"
                class="mb-3"
              ></v-text-field>

              <v-text-field
                v-model="employeeData.nationality"
                outlined
                dense
                :label="$t('nationality')"
                :placeholder="$t('nationality')"
                hide-details="auto"
                maxlength="100"
                class="mb-3"
              ></v-text-field>

              <v-switch v-model="employeeData.kids" :label="$t('kids')" class="mt-0" hide-details="auto" />
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="index === 1">
              <v-row>
                <v-col
                  v-for="(assignee, assigneeIndex) in employeeData.assignedPositions"
                  :key="`Assignee--${assigneeIndex}`"
                  cols="12"
                >
                  <v-card outlined class="card--border-primary">
                    <v-card-title>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="assignee.position"
                            :items="assigneePositionChoices"
                            outlined
                            clearable
                            dense
                            hide-details="auto"
                            :label="$t('position')"
                            :placeholder="$t('position')"
                            :rules="[validators.required]"
                          >
                            <template #append-outer>
                              <v-tooltip top transition="scroll-y-transition" open-delay="150">
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    color="error"
                                    x-small
                                    v-bind="attrs"
                                    @click="deleteAssignee(assigneeIndex)"
                                    v-on="on"
                                  >
                                    <v-icon class="me-2" size="16">
                                      {{ icons.mdiTrashCanOutline }}
                                    </v-icon>
                                    <span v-t="'delete'" />
                                  </v-btn>
                                </template>
                                <span v-t="'delete'" class="text-xs" />
                              </v-tooltip>
                            </template>
                            <template #item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title
                                  >{{ item.text }}
                                  <span
                                    v-if="item.assigneesCount - item.demand === 0"
                                    class="primary--text font-weight-medium text-capitalize ml-2"
                                    >{{ item.assigneesCount }}/{{ item.demand }}</span
                                  >
                                  <span
                                    v-if="item.assigneesCount - item.demand > 0"
                                    class="success--text font-weight-medium text-capitalize ml-2"
                                    >{{ item.assigneesCount }}/{{ item.demand }}</span
                                  >
                                  <span
                                    v-if="item.assigneesCount - item.demand < 0"
                                    class="error--text font-weight-medium text-capitalize ml-2"
                                    >{{ item.assigneesCount }}/{{ item.demand }}</span
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <upgrade-alert-wrapper>
                            <template #blocked-features>
                              <v-col cols="12">
                                <v-menu
                                  close-on-content-click
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                  :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                >
                                  <template v-slot:activator="activator">
                                    <v-text-field
                                      v-model="assignee.dateStart"
                                      :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                      :label="$t('assignmentDateStart')"
                                      readonly
                                      outlined
                                      dense
                                      clearable
                                      v-bind="activator.attrs"
                                      hide-details="auto"
                                      :rules="[validators.dateRangeValidator(assignee.dateStart, assignee.dateEnd)]"
                                      v-on="activator.on"
                                    />
                                  </template>
                                  <v-date-picker
                                    v-model="assignee.dateStart"
                                    :first-day-of-week="1"
                                    :locale="$i18n.locale"
                                  />
                                </v-menu>
                              </v-col>
                              <v-col cols="12">
                                <v-menu
                                  :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="activator">
                                    <v-text-field
                                      v-model="assignee.dateEnd"
                                      :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                      :label="$t('assignmentDateEnd')"
                                      readonly
                                      outlined
                                      dense
                                      clearable
                                      v-bind="activator.attrs"
                                      hide-details="auto"
                                      :rules="[validators.dateRangeValidator(assignee.dateStart, assignee.dateEnd)]"
                                      v-on="activator.on"
                                    />
                                  </template>
                                  <v-date-picker
                                    v-model="assignee.dateEnd"
                                    :first-day-of-week="1"
                                    :locale="$i18n.locale"
                                  />
                                </v-menu>
                              </v-col>
                            </template>
                          </upgrade-alert-wrapper>
                        </v-col>
                      </v-row>
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-btn v-t="'addAssignment'" color="primary" block @click="addAssignee" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="index === 2">
              <v-text-field
                v-for="customField in customFields"
                :key="customField.id"
                v-model="customField.field"
                outlined
                dense
                :label="customField.name"
                :placeholder="customField.name"
                hide-details
                maxlength="100"
                class="mb-3"
              ></v-text-field>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="index === 3">
              <v-btn-toggle v-model="employeeData.addressType" mandatory color="primary">
                <v-btn v-t="'noAddressOption'" class="text--primary" left outlined />
                <v-btn v-t="'customAddressOption'" class="text--primary" left outlined />
                <v-btn v-t="'companyAddressOption'" class="text--primary" right outlined />
              </v-btn-toggle>
              <div v-if="employeeData.addressType === 1" class="pt-5">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      v-model="employeeData.customAddress.name"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('name')"
                      :placeholder="$t('name')"
                      :rules="[validators.required]"
                      maxlength="50"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      v-model="employeeData.customAddress.street"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('street')"
                      :placeholder="$t('street')"
                      maxlength="100"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                    <v-text-field
                      v-model="employeeData.customAddress.houseNumber"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('houseNumber')"
                      :placeholder="$t('houseNumber')"
                      maxlength="20"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pl-3' : '']">
                    <v-text-field
                      v-model="employeeData.customAddress.flatNumber"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('flatNumber')"
                      :placeholder="$t('flatNumber')"
                      maxlength="20"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="8" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                    <v-text-field
                      v-model="employeeData.customAddress.city"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('city')"
                      :placeholder="$t('city')"
                      maxlength="50"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" :class="[$vuetify.breakpoint.smAndUp ? 'pl-3' : '']">
                    <v-text-field
                      v-model="employeeData.customAddress.zipCode"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('zipCode')"
                      :placeholder="$t('zipCode')"
                      maxlength="10"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                    <v-text-field
                      v-model="employeeData.customAddress.state"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('state')"
                      :placeholder="$t('state')"
                      maxlength="50"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.mdAndUp ? 'pl-3' : '']">
                    <v-text-field
                      v-model="employeeData.customAddress.country"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('country')"
                      :placeholder="$t('country')"
                      maxlength="50"
                      class="mb-3"
                    ></v-text-field> </v-col
                ></v-row>
                <upgrade-alert-wrapper class="mb-3" :form="false">
                  <template #blocked-features>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-menu
                          close-on-content-click
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        >
                          <template v-slot:activator="activator">
                            <v-text-field
                              v-model="employeeData.customAddress.dateStart"
                              :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                              :label="$t('accommodationDateStart')"
                              readonly
                              outlined
                              dense
                              clearable
                              v-bind="activator.attrs"
                              hide-details="auto"
                              :rules="[
                                validators.dateRangeValidator(
                                  employeeData.customAddress.dateStart,
                                  employeeData.customAddress.dateEnd,
                                ),
                              ]"
                              class="mb-3"
                              v-on="activator.on"
                            />
                          </template>
                          <v-date-picker
                            v-model="employeeData.customAddress.dateStart"
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-menu
                          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          close-on-content-click
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="activator">
                            <v-text-field
                              v-model="employeeData.customAddress.dateEnd"
                              :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                              :label="$t('accommodationDateEnd')"
                              readonly
                              outlined
                              dense
                              clearable
                              v-bind="activator.attrs"
                              hide-details="auto"
                              :rules="[
                                validators.dateRangeValidator(
                                  employeeData.customAddress.dateStart,
                                  employeeData.customAddress.dateEnd,
                                ),
                              ]"
                              v-on="activator.on"
                            />
                          </template>
                          <v-date-picker
                            v-model="employeeData.customAddress.dateEnd"
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </template>
                </upgrade-alert-wrapper>
              </div>
              <div v-else-if="employeeData.addressType === 2" class="pt-5">
                <v-autocomplete
                  v-model="employeeData.companyAddress.room"
                  :items="roomOccupantsChoices"
                  outlined
                  clearable
                  dense
                  hide-details="auto"
                  :label="$t('room')"
                  :placeholder="$t('rooms')"
                  :rules="[validators.nonEmptyValueValidator]"
                  class="mb-3"
                >
                  <template v-if="employeeData.companyAddress.room" #append-outer>
                    <v-fade-transition leave-absolute>
                      <v-icon color="primary" @click="goToRoomDetailsPage(employeeData.companyAddress.room)">{{
                        icons.mdiOpenInNew
                      }}</v-icon>
                    </v-fade-transition>
                  </template>
                  <template #item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ item.text }}
                        <span
                          v-if="item.occupantsCount - item.demand === 0"
                          class="primary--text font-weight-medium text-capitalize ml-2"
                          >{{ item.occupantsCount }}/{{ item.demand }}</span
                        >
                        <span
                          v-if="item.occupantsCount - item.demand > 0"
                          class="success--text font-weight-medium text-capitalize ml-2"
                          >{{ item.occupantsCount }}/{{ item.demand }}</span
                        >
                        <span
                          v-if="item.occupantsCount - item.demand < 0"
                          class="error--text font-weight-medium text-capitalize ml-2"
                          >{{ item.occupantsCount }}/{{ item.demand }}</span
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <upgrade-alert-wrapper :form="false" class="mb-3">
                  <template #blocked-features>
                    <v-menu
                      close-on-content-click
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                    >
                      <template v-slot:activator="activator">
                        <v-text-field
                          v-model="employeeData.companyAddress.dateStart"
                          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          :label="$t('accommodationDateStart')"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="activator.attrs"
                          hide-details="auto"
                          :rules="[
                            validators.dateRangeValidator(
                              employeeData.companyAddress.dateStart,
                              employeeData.companyAddress.dateEnd,
                            ),
                          ]"
                          class="mb-3"
                          v-on="activator.on"
                        />
                      </template>
                      <v-date-picker
                        v-model="employeeData.companyAddress.dateStart"
                        :first-day-of-week="1"
                        :locale="$i18n.locale"
                      />
                    </v-menu>
                    <v-menu
                      :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                      close-on-content-click
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="activator">
                        <v-text-field
                          v-model="employeeData.companyAddress.dateEnd"
                          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          :label="$t('accommodationDateEnd')"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="activator.attrs"
                          hide-details="auto"
                          :rules="[
                            validators.dateRangeValidator(
                              employeeData.companyAddress.dateStart,
                              employeeData.companyAddress.dateEnd,
                            ),
                          ]"
                          class="mb-3"
                          v-on="activator.on"
                        />
                      </template>
                      <v-date-picker
                        v-model="employeeData.companyAddress.dateEnd"
                        :first-day-of-week="1"
                        :locale="$i18n.locale"
                      />
                    </v-menu>
                  </template>
                </upgrade-alert-wrapper>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-btn v-if="employeeData.file === null" color="primary" class="mb-6" block @click="addFileToUpload">{{
          $t('addFile')
        }}</v-btn>

        <v-card v-else outlined class="mb-6">
          <v-card-title>File</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <file-pond
                  v-model="employeeData.file.file"
                  :label-idle="$t('filePondIdle')"
                  :allow-multiple="false"
                  accepted-file-types="application/pdf, image/png, image/jpeg"
                  max-file-size="20MB"
                  max-total-file-size="20MB"
                  @addfile="setUploadedFileName(employeeData.file)"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="employeeData.file.name"
                  :label="$t('title')"
                  :placeholder="$t('title')"
                  outlined
                  dense
                  maxlength="150"
                  hide-details="auto"
                  :rules="[validators.required]"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-combobox
                  v-model="employeeData.file.types"
                  :items="fileTypes"
                  :label="$t('type')"
                  :placeholder="$t('type')"
                  item-text="type"
                  item-value="id"
                  hide-details="auto"
                  multiple
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="10">
                <v-menu
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="employeeData.file.validUntil"
                      :label="$t('validUntil', { date: '' })"
                      :placeholder="$t('validUntil', { date: '' })"
                      readonly
                      outlined
                      dense
                      clearable
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="employeeData.file.validUntil"
                    :first-day-of-week="1"
                    :locale="$i18n.locale"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-end">
                <v-tooltip top transition="scroll-y-transition" open-delay="150" color="secondary">
                  <template #activator="{ on, attrs }">
                    <v-btn elevation="0" color="error" icon v-bind="attrs" @click="removeFileToUpload" v-on="on">
                      <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-xs"> {{ $t('removeFile') }} </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn color="primary" class="me-3" type="submit" :disabled="!valid" block :loading="loading">
              {{ $t('add') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn color="secondary" outlined type="reset" block @click="resetEmployeeData">
              {{ $t('discard') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { required, emailValidator, dateRangeValidator, nonEmptyValueValidator } from '@core/utils/validation'

import { mdiClose, mdiTrashCanOutline } from '@mdi/js'
import { getCurrentInstance, ref, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import _ from 'lodash'
import { useRouter } from '@core/utils'
import employeeUtils from '@/utils/employeeUtils'
import fileUploadUtils from '@/utils/fileUploadUtils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import UpgradeAlertWrapper from '@/components/UpgradeAlertWrapper.vue'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileEncode, FilePondPluginFileValidateSize)

export default {
  components: {
    UpgradeAlertWrapper,
    FilePond,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const blankEmployeeData = {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      nationality: '',
      maritalStatus: '',
      languages: [],
      tags: [],
      kids: false,
      status: null,
      occupantRoom: null,
      assignedPositions: [],
      addressType: 0,
      customAddress: {
        name: '',
        street: '',
        zipCode: '',
        houseNumber: '',
        flatNumber: '',
        city: '',
        state: '',
        country: '',
      },
      companyAddress: {
        room: null,
        dateStart: null,
        dateEnd: null,
      },
      file: null,
      workingStartDay: new Date().toISOString().substr(0, 10),
    }

    const expansionPanels = [
      {
        title: 'extraData',
      },
      {
        title: 'positions',
      },
      {
        title: 'customFields',
      },
      {
        title: 'address',
      },
    ]
    const panel = ref([])

    const { createEmployee, getRoomOccupantsChoices } = useActions('staff', [
      'createEmployee',
      'getRoomOccupantsChoices',
    ])
    const { getCandidateCustomFields } = useActions('company', ['getCandidateCustomFields'])

    const {
      fetchLanguageChoices,
      fetchStatusChoices,
      fetchAssigneePositionChoices,
      fetchTagChoices,
      fetchFileTypes,

      languageChoices,
      statusChoices,
      assigneePositionChoices,
      tagsChoices,
      fileTypes,
    } = employeeUtils()

    const loading = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const searchInput = ref(null)

    const customFields = ref([])

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const employeeData = ref(_.cloneDeep(blankEmployeeData))

    const resetEmployeeData = () => {
      resetForm()
      employeeData.value = _.cloneDeep(blankEmployeeData)
      panel.value = []
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        const { customAddress, companyAddress, addressType, file, tags, ...restEmployeeData } = employeeData.value
        let addressToAdd = {}
        if (addressType === 1) {
          addressToAdd = { customAddress }
        } else if (addressType === 2) {
          addressToAdd = { companyAddress }
        }
        const payload = {
          ...restEmployeeData,
          ...addressToAdd,
          ...(file !== null
            ? {
                file: {
                  name: file.name,
                  validUntil: file.validUntil,
                  file: file.file[0].getFileEncodeBase64String(),
                  types: file.types.map((type) => (_.isPlainObject(type) ? type : { type })),
                },
              }
            : {}),
          customFields: customFields.value.map((field) => {
            return { customField: field.id, field: field.field }
          }),
          tags: tags.map((tag) => {
            if (_.isObject(tag)) return tag

            return { tag }
          }),
        }
        loading.value = true
        await createEmployee(payload)
        loading.value = false
        resetEmployeeData()
        emit('refetch-data')
      } else {
        validate()
      }
    }
    const { setUploadedFileName } = fileUploadUtils()

    const addFileToUpload = () => {
      employeeData.value.file = {
        file: {},
        name: '',
        validUntil: null,
        types: [],
      }
    }
    const removeFileToUpload = () => {
      employeeData.value.file = null
    }

    const addAssignee = () => {
      employeeData.value.assignedPositions.push({
        position: null,
        dateStart: null,
        dateEnd: null,
      })
    }

    const deleteAssignee = (index) => {
      employeeData.value.assignedPositions.splice(index, 1)
    }

    const { router } = useRouter()
    const goToRoomDetailsPage = (roomId) => {
      if (!roomId) return
      const route = router.resolve({ name: 'apps-house-room-view', params: { id: roomId } })
      window.open(route.href, '_blank')
    }

    const roomOccupantsChoices = ref([])

    watch(
      () => props.isAddNewUserSidebarActive,
      async (newVal) => {
        if (newVal) {
          await fetchFileTypes()
          await fetchLanguageChoices()
          await fetchStatusChoices()
          customFields.value = await getCandidateCustomFields()
          if (vm.$can(ACTIONS.READ, PRIVATE.STAFF.PROJECTS)) await fetchAssigneePositionChoices()
          await fetchTagChoices()
          if (vm.$can(ACTIONS.READ, PRIVATE.STAFF.HOUSES)) {
            roomOccupantsChoices.value = await getRoomOccupantsChoices()
          }
        }
      },
    )

    return {
      setUploadedFileName,
      addFileToUpload,
      removeFileToUpload,
      resetEmployeeData,
      onSubmit,
      addAssignee,
      deleteAssignee,
      goToRoomDetailsPage,

      form,
      loading,
      employeeData,
      valid,
      searchInput,
      customFields,
      statusChoices,
      languageChoices,
      assigneePositionChoices,
      tagsChoices,
      fileTypes,
      expansionPanels,
      panel,
      roomOccupantsChoices,

      ACTIONS,
      PRIVATE,

      validators: { required, emailValidator, dateRangeValidator, nonEmptyValueValidator },
      icons: {
        mdiClose,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
@keyframes add-notify {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
}

@keyframes notify-add-mark {
  0%,
  to {
    transform: rotate(0deg);
    transform-origin: top center;
  }
  10%,
  90% {
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
}

.add_notify__g {
  animation: notify-add-mark 5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both infinite;
  animation-play-state: inherit;
}

.add_notify__path {
  animation: add-notify 1s 1s infinite both;
  transform-origin: center center;
  animation-play-state: inherit;
}

.add_notify__svg {
  cursor: pointer;
  animation-play-state: running;
}

.add_notify__svg:hover {
  animation-play-state: running;
}

@keyframes remove-notify {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
}

@keyframes notify-remove-mark {
  0%,
  to {
    transform: rotate(0deg);
    transform-origin: top center;
  }
  10%,
  90% {
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
}

.remove_notify__g {
  animation: notify-remove-mark 5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both infinite;
  animation-play-state: inherit;
}

.remove_notify__path {
  animation: remove-notify 1s 1s infinite both;
  transform-origin: center center;
  animation-play-state: inherit;
}

.remove_notify__svg {
  cursor: pointer;
  animation-play-state: running;
}

.remove_notify__svg:hover {
  animation-play-state: running;
}
</style>
