var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"value":_vm.isExportSidebarActive,"temporary":"","touchless":"","right":!_vm.$vuetify.rtl,"width":_vm.$vuetify.breakpoint.mdAndUp ? '40%' : '100%',"app":""},on:{"input":function (val) { return _vm.$emit('update:is-export-sidebar-active', val); }}},[_c('div',{staticClass:"drawer-header d-flex align-center"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('export'),expression:"'export'"}],staticClass:"font-weight-semibold text-base text--primary"}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('update:is-export-sidebar-active', false)}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"color":"info","text":""}},[_c('p',{directives:[{name:"t",rawName:"v-t",value:('exportAlert'),expression:"'exportAlert'"}],staticClass:"font-weight-semibold mb-1"})])],1)],1),_c('v-subheader',{directives:[{name:"t",rawName:"v-t",value:('include'),expression:"'include'"}]}),_c('v-row',_vm._l((_vm.exportFields),function(exportField,index){return _c('v-col',{key:("exportFields--" + index),attrs:{"cols":"6"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":"auto","label":_vm.$t(exportField.text, { postfix: '' })},model:{value:(exportField.include),callback:function ($$v) {_vm.$set(exportField, "include", $$v)},expression:"exportField.include"}})],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":_vm.$t('columnNameLanguage'),"items":[
              {
                text: _vm.$t('english'),
                value: 'en',
              },
              {
                text: _vm.$t('polish'),
                value: 'pl',
              } ],"rules":[_vm.validators.required],"outlined":"","dense":"","hide-details":""},model:{value:(_vm.columnNameLanguage),callback:function ($$v) {_vm.columnNameLanguage=$$v},expression:"columnNameLanguage"}})],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"overwritten-drawer-actions"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","type":"submit","disabled":!_vm.valid,"block":"","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('download')))])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('discard'),expression:"'discard'"}],attrs:{"color":"secondary","outlined":"","type":"reset","block":""},on:{"click":function($event){return _vm.$emit('update:is-export-sidebar-active', false)}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }