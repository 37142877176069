<template>
  <v-navigation-drawer
    :value="isExportSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => $emit('update:is-export-sidebar-active', val)"
  >
    <div class="drawer-header d-flex align-center">
      <span v-t="'export'" class="font-weight-semibold text-base text--primary" />
      <v-spacer></v-spacer>
      <v-btn icon small @click="$emit('update:is-export-sidebar-active', false)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-alert color="info" text>
              <p v-t="'exportAlert'" class="font-weight-semibold mb-1" />
            </v-alert>
          </v-col>
        </v-row>
        <v-subheader v-t="'include'" />
        <v-row>
          <v-col v-for="(exportField, index) in exportFields" :key="`exportFields--${index}`" cols="6">
            <v-switch
              v-model="exportField.include"
              hide-details="auto"
              class="mt-0"
              :label="$t(exportField.text, { postfix: '' })"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="columnNameLanguage"
              :label="$t('columnNameLanguage')"
              :items="[
                {
                  text: $t('english'),
                  value: 'en',
                },
                {
                  text: $t('polish'),
                  value: 'pl',
                },
              ]"
              :rules="[validators.required]"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn color="primary" class="me-3" type="submit" :disabled="!valid" block :loading="loading">
              {{ $t('download') }}</v-btn
            >
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              v-t="'discard'"
              color="secondary"
              outlined
              type="reset"
              block
              @click="$emit('update:is-export-sidebar-active', false)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

export default {
  model: {
    prop: 'isExportSidebarActive',
    event: 'update:is-export-sidebar-active',
  },
  props: {
    isExportSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const exportFields = [
      {
        text: 'employeeId',
        columnName: 'employeeId',
        field: 'id',
        include: true,
      },
      {
        text: 'firstName',
        columnName: 'firstName',
        field: 'first_name',
        include: true,
      },
      {
        text: 'lastName',
        columnName: 'lastName',
        field: 'last_name',
        include: true,
      },
      {
        text: 'email',
        columnName: 'email',
        field: 'email',
        include: true,
      },
      {
        text: 'phoneNumber',
        columnName: 'phoneNumber',
        field: 'phone',
        include: true,
      },
      {
        text: 'martialStatus',
        columnName: 'martialStatus',
        field: 'marital_status',
        include: true,
      },
      {
        text: 'nationality',
        columnName: 'nationality',
        field: 'nationality',
        include: true,
      },
      {
        text: 'kids',
        columnName: 'kids',
        field: 'kids',
        include: true,
      },
      {
        text: 'languages',
        columnName: 'languages',
        field: 'languages',
        include: true,
      },
      {
        text: 'status',
        columnName: 'status',
        field: 'status',
        include: true,
      },
      {
        text: 'workingStartDay',
        columnName: 'workingStartDay',
        field: 'working_start_day',
        include: true,
      },
      {
        text: 'tags',
        columnName: 'tags',
        field: 'tags',
        include: true,
      },
      {
        text: 'filesCount',
        columnName: 'filesCount',
        field: 'files_count',
        include: true,
      },
      {
        text: 'customFields',
        field: 'custom_fields',
        include: true,
      },
    ]

    if (vm.$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)) {
      exportFields.push(
        {
          text: 'currentAssignment',
          field: 'current_assignment',
          include: true,
        },
        {
          text: 'futureAssignment',
          field: 'future_assignment',
          include: true,
        },
        {
          text: 'pastAssignment',
          field: 'past_assignment',
          include: true,
        },
        {
          text: 'currentOccupancy',
          field: 'current_occupancy',
          include: true,
        },
        {
          text: 'futureOccupancy',
          field: 'future_occupancy',
          include: true,
        },
        {
          text: 'pastOccupancy',
          field: 'past_occupancy',
          include: true,
        },
        {
          text: 'currentCustomAddress',
          field: 'current_custom_address',
          include: true,
        },
        {
          text: 'futureCustomAddress',
          field: 'future_custom_address',
          include: true,
        },
        {
          text: 'pastCustomAddress',
          field: 'past_custom_address',
          include: true,
        },
      )
    } else {
      exportFields.push(
        {
          text: 'allAssignment',
          field: 'all_assignment',
          include: true,
        },
        {
          text: 'allOccupancy',
          field: 'all_occupancy',
          include: true,
        },
        {
          text: 'allCustomAddress',
          field: 'all_custom_address',
          include: true,
        },
      )
    }

    const { exportEmployees } = useActions('staff', ['exportEmployees'])

    const columnNameLanguage = ref(vm.$i18n.locale)

    const loading = ref(false)
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const onSubmit = async () => {
      if (valid.value) {
        loading.value = true
        const fields = []
        const columnNames = []
        exportFields.forEach((field) => {
          if (field.include) {
            fields.push(field.field)
            if ('columnName' in field)
              columnNames.push(vm.$t(field.columnName, columnNameLanguage.value, { postfix: '' }))
            if (field.field === 'current_address') {
              fields.push('addressType', 'house', 'room')
              columnNames.push(vm.$t('addressType'), vm.$t('house'), vm.$t('room'))
            }
            if (
              ['current_assignment', 'future_assignment', 'past_assignment', 'all_assignment'].includes(field.field)
            ) {
              fields.push(
                'assignment_position',
                'assignment_project',
                'assignment_client',
                'assignment_date_start',
                'assignment_date_end',
                'assignment_group',
              )
              columnNames.push(
                vm.$t('assignmentPosition'),
                vm.$t('assignmentProject'),
                vm.$t('assignmentClient'),
                vm.$t('assignmentPeriodStart'),
                vm.$t('assignmentPeriodEnd'),
                vm.$t('assignmentGroup'),
              )
            }
            if (['current_occupancy', 'future_occupancy', 'past_occupancy', 'all_occupancy'].includes(field.field)) {
              fields.push(
                'occupancy_house',
                'occupancy_house_address',
                'occupancy_room',
                'occupancy_date_start',
                'occupancy_date_end',
                'occupancy_group',
              )
              columnNames.push(
                vm.$t('occupancy_house'),
                vm.$t('occupancy_house_address'),
                vm.$t('occupancy_room'),
                vm.$t('occupancy_date_start'),
                vm.$t('occupancy_date_end'),
                vm.$t('occupancy_group'),
              )
            }
            if (
              ['current_custom_address', 'future_custom_address', 'past_custom_address', 'all_custom_address'].includes(
                field.field,
              )
            ) {
              fields.push(
                'custom_address_name',
                'custom_address_address',
                'custom_address_date_start',
                'custom_address_date_end',
                'custom_address_group',
              )
              columnNames.push(
                vm.$t('custom_address_name'),
                vm.$t('custom_address_address'),
                vm.$t('custom_address_date_start'),
                vm.$t('custom_address_date_end'),
                vm.$t('custom_address_group'),
              )
            }
          }
        })

        const { data, fileName, success } = await exportEmployees({
          fields,
          column_names: columnNames,
        })
        if (success) {
          const anchor = document.createElement('a')
          anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`
          anchor.target = '_blank'
          anchor.download = fileName
          anchor.click()
          anchor.remove()
        }

        loading.value = false
        emit('update:is-export-sidebar-active', false)
      } else {
        validate()
      }
    }

    return {
      onSubmit,

      exportFields,
      columnNameLanguage,
      form,
      loading,
      valid,
      validators: { required },

      icons: {
        mdiClose,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
