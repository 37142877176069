<template>
  <div id="user-list">
    <!--     app drawer -->
    <employee-list-add-new
      v-model="isAddNewUserSidebarActive"
      @refetch-data="
        fetchEmployees()
        fetchEmployeesOverview()
      "
    ></employee-list-add-new>
    <employee-list-export v-model="isExportSidebarActive"></employee-list-export>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col v-for="total in overview" :key="total.title" cols="12" md="4">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.count }}
              </h2>
              <span>{{ $t(`${total.title}`) }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}--text`"
            >
              <v-icon size="25" :color="resolveUserTotalIcon(total.title).color" class="rounded-0">
                {{ resolveUserTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card class="mb-12">
      <v-card-title v-t="'searchAndFilter'" />
      <v-row class="px-2 ma-0">
        <v-col cols="12" sm="4">
          <v-select
            v-model="statusesFilter"
            :placeholder="$t('selectStatus')"
            :items="choices.statusChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            v-model="tagsFilter"
            :placeholder="$t('selectTag')"
            :items="choices.tagsChoices"
            outlined
            clearable
            dense
            multiple
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <v-autocomplete
            v-model="languagesFilter"
            :placeholder="$t('selectLanguage')"
            :items="choices.languageChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('search')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keydown="delaySearchHandler"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isExportSidebarActive = !isExportSidebarActive">
            <v-icon class="me-2">{{ icons.mdiExportVariant }}</v-icon>
            <span v-t="'export'" />
          </v-btn>
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive">
            <v-icon class="me-2">{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('addEmployee') }}</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="employees"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalUserListTable"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPage'),
          'items-per-page-options': [10, 50, 250],
        }"
      >
        <template #[`top`]>
          <v-dialog v-model="isDialogVisible" max-width="650px" persistent>
            <v-card class="pa-sm-10 pa-3">
              <v-card-title v-t="'delete'" class="justify-center text-h5" />
              <v-card-text>
                <v-row>
                  <v-col v-t="'confirmationModalUndoneQuestion'" cols="12" class="d-flex align-center justify-center" />
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined class="me-3" @click="discardDelete" />
                    <v-btn v-t="'delete'" color="error" @click="confirmDelete" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <!-- name -->
        <template #[`header.fullName`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.status`]="{ header }">
          {{ $t(header.text, { postfix: '' }) }}
        </template>
        <template #[`header.tags`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.phoneNumber`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`item.fullName`]="{ item }">
          <div class="d-flex align-center ms-3">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
              <span v-else class="font-weight-medium">{{ avatarText(`${item.firstName} ${item.lastName}`) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-employee-view', params: { id: item.id } }"
                class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.firstName }} {{ item.lastName }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.status`]="{ item }">
          {{ emptyValueFormatter(item.status) }}
        </template>

        <template #[`item.tags`]="{ item }">
          <span v-if="!(item.tags && item.tags.length)">-/-</span>
          <v-chip
            v-for="(tag, index) in item.tags"
            v-else
            :key="index"
            small
            label
            color="info"
            outlined
            class="font-weight-semibold ma-1"
            v-text="tag.tag"
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-tooltip v-if="$can(ACTIONS.DELETE, item)" bottom>
              <template #activator="tooltipActivator">
                <v-btn
                  icon
                  small
                  color="error"
                  v-bind="tooltipActivator.attrs"
                  v-on="tooltipActivator.on"
                  @click="deleteItem(item.id)"
                >
                  <v-icon size="18">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'delete'" />
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  :to="{ name: 'apps-employee-view', params: { id: item.id } }"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>View employee</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiDeleteOutline, mdiEyeOutline, mdiExportVariant } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText, emptyValueFormatter } from '@core/utils/filter'
import { useActions } from 'vuex-composition-helpers'
import EmployeeListAddNew from './EmployeeListAddNew.vue'
import useEmployeeList from './useEmployeeList'
import EmployeeListExport from './EmployeeListExport.vue'
import { ACTIONS } from '@/plugins/acl/const'

export default {
  components: {
    EmployeeListAddNew,
    EmployeeListExport,
  },
  setup() {
    const isDialogVisible = ref(false)
    const isAddNewUserSidebarActive = ref(false)
    const isExportSidebarActive = ref(false)

    const { deleteEmployee } = useActions('staff', ['deleteEmployee'])

    const {
      tableColumns,
      searchQuery,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      overview,
      employees,
      choices,
      tagsFilter,
      statusesFilter,
      languagesFilter,
      fetchEmployees,
      fetchEmployeesOverview,
      resolveUserTotalIcon,
      delaySearchHandler,
    } = useEmployeeList()

    onMounted(async () => {
      await fetchEmployeesOverview()
    })

    const itemToDeleteId = ref(null)
    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDialogVisible.value = true
    }
    const confirmDelete = async () => {
      if (itemToDeleteId.value !== null) {
        await deleteEmployee(itemToDeleteId.value)
        await fetchEmployees()
        await fetchEmployeesOverview()
      }
      isDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDialogVisible.value = false
    }

    return {
      avatarText,
      emptyValueFormatter,
      resolveUserTotalIcon,
      fetchEmployees,
      fetchEmployeesOverview,
      delaySearchHandler,

      deleteItem,
      confirmDelete,
      discardDelete,

      isDialogVisible,
      tableColumns,
      searchQuery,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      isExportSidebarActive,
      overview,
      employees,
      choices,
      tagsFilter,
      statusesFilter,
      languagesFilter,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiExportVariant,
      },

      ACTIONS,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
